.App {
  text-align: center;
}

h1, h2, h3, h4, h5 { 
	font-family: ClarikaGeometric-Black; 
	font-weight: normal; 
	font-style: normal; 
}

.App-logo {
  height: 10vmin;
}

.App-header {
  background-color: #2E2E2E;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(1rem);
  color: white;
}

.App-link {
  color: #09d3ac;
}
